.input__container-light {
    display: flex;
    column-gap: 15px;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;

    .p-autocomplete {
        width: 60%;
    }

    .p-inputtext {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px;
        color: #495057;
        background: #ffffff;
        padding: 8px 12px;
        border: 1px solid #ced4da;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px;
        width: 100%;
    }

    .p-inputtext:enabled:focus {
        box-shadow: 0 0 0 0.1rem #C7D2FE;
    }

    .p-icon {
        width: 12px;
        height: 12px
    }

}

.input__container-dark {
    display: flex;
    column-gap: 15px;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;

    .p-autocomplete {
        width: 60%;
    }

    .p-inputtext {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px;
        color: rgba(255, 255, 255, .87) !important;
        background: #20262e !important;
        padding: 8px 12px;
        border: 1px solid #2a323d;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px;
        width: 100%;
    }

    .p-inputtext:enabled:focus {
        box-shadow: 0 0 0 0.1rem #2a323d;
    }

    .p-icon {
        width: 12px;
        height: 12px
    }

    .p-dropdown .p-dropdown-trigger {
        background: #20262e !important;
    }

    .p-dropdown .p-dropdown-label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }
}

.p-dropdown-item {
    font-size: 12px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 5px 10px !important
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0px
}

.div__icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 15px
}

.button__option {
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #59527C;
}

.div__optionA {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 6rem;
    margin-bottom: 25px;
    margin-top: 25px;

    .container__warning {
        padding: 0 25px;

        .icon__warning {
            color: #FFC12E;
            font-size: 20px;
            margin-right: 10px;
        }
    }

    .span_warning {
        font-size: 14px;
        font-style: italic;
        letter-spacing: 0.2px;
    }
}