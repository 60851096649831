.App {
  text-align: center;
}

.container {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.home-background-phone {
  // background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(58, 255, 251, 1) 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: cover;
}

.home-background-web {
  height: 100vh;
  // background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(58, 255, 251, 1) 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 3rem;
}