.custom-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
  background-color: #ffffff;
  display: flex;
  padding: 12px 10px;

  &.card--rectangle {
    justify-content: center;
  }

  &.card--square {
    flex-direction: column;
    padding: 20px 10px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
      background-color: rgba(157, 193, 251, 0.209);
    }

    &.card--square.blocked-card {
      background-color: rgba(157, 157, 157, 0.308);
      cursor: not-allowed;
    }

    .card__content--square {
      width: 100%;

      .card__title--square {
        font-size: 1rem;
        margin: 0px 5px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-align: center;
        color: black;
      }
    }
  }

  &.card--rectangle {
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    &.clickable-card:hover {
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
      background-color: rgba(157, 193, 251, 0.189);
      border: 1px solid #3981F6;
      cursor: pointer;

    }

    .card__content--rectangle {
      width: 50%;

      .card__title--rectangle {
        font-size: 1.20rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: black;
      }
    }

    .card__image--rectangle {
      width: 30%;
      width: 125px;
    }
  }

  .card__image {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 12px;
    width: 30%;
    height: 100%;
  }

  .card__content {
    position: absolute;
    top: 5px;
    left: 0;
    padding: 10px;
    color: black;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card__number {
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
}

.customCardIcon {
  padding: 25px
}

.div__customTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .span__customCardNumber {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }

}

.span__customCardTitle {
  font-size: 16px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}