.custom_table__light {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border: 1px solid #d7d7d7;
    border-radius: 5px;

    .p-inputtext {
        font-size: 12px;
        padding: 6px;
    }

    .p-paginator .p-dropdown {
        height: 2rem;
        margin-left: 0px;
    }

    .p-icon {
        height: 10px;
        width: 10px
    }

    .p-dropdown .p-dropdown-trigger {
        width: 25px
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        font-size: 14px;
    }

    .p-paginator .p-paginator-current {
        height: 2rem;
        font-size: 12px;
    }

    .p-datatable .p-sortable-column .p-sortable-column-icon {
        width: 8px;
        height: 8px;
        color: #7e868c;
    }

    .p-datatable .p-datatable-thead>tr>th {
        background: #F0F2F4;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 12px;
        color: #7e868c;
        border-radius: 2px;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        text-align: left;
        border: 1px solid #dee2e6;
        border-width: 0 0 1px 0;
        padding: 6px 8px;
        font-size: 12px;
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        min-width: 12px;
        height: 2rem;
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 2rem;
        height: 2rem;
    }
}

.custom_table__dark {
    box-shadow: 0 3px 10px rgb(0 0 0 / 83%);
    border: 1px solid #3f4b5b;
    border-radius: 5px;

    .p-inputtext {
        font-size: 12px;
        padding: 6px;
    }

    .p-paginator .p-dropdown {
        height: 2rem;
        margin-left: 0px;
    }

    .p-icon {
        height: 10px;
        width: 10px
    }

    .p-dropdown .p-dropdown-trigger {
        width: 25px
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        font-size: 14px;
    }

    .p-paginator .p-paginator-current {
        height: 2rem;
        font-size: 12px;
    }

    .p-datatable .p-sortable-column .p-sortable-column-icon {
        width: 8px;
        height: 8px;
        color: #7e868c;
    }

    .p-datatable .p-datatable-thead>tr>th {
        background: #2A323D;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 12px;
        color: rgba(255, 255, 255, .87);
        border-radius: 1px;
        border-bottom: 1px solid #3f4b5b;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        text-align: left;
        border: 1px solid #3f4b5b;
        border-width: 0 0 1px 0;
        padding: 6px 8px;
        font-size: 14px;
        background: #2a323d;
        color: rgba(255, 255, 255, .87);

    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        min-width: 12px;
        height: 2rem;
        color: rgba(255, 255, 255, .87);
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 2rem;
        height: 2rem;
        color: rgba(255, 255, 255, .87);
    }

    .p-datatable .p-paginator-bottom {
        border-width: 0 0 1px 0;
        border-radius: 0;
        background: #2a323d;
        color: rgba(255, 255, 255, .87);
    }

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: #8dd0ff;
        color: #2a323d;
    }

    .p-paginator .p-paginator-current {
        color: rgba(255, 255, 255, .87);
    }

    .p-link:focus {
        box-shadow: none;
    }

    .p-paginator .p-dropdown {
        color: #2a323d;
        background: #8dd0ff;
    }

    .p-dropdown:not(.p-disabled).p-focus {
        border-color: #8dd0ff;
        box-shadow: none;
    }

}

.dark-context {
    background: #20262e !important;

    .p-menuitem-text,
    .p-menuitem-icon {
        color: white !important;
    }
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: rgb(255 255 255 / 12%) !important
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
    background: rgb(255 255 255 / 12%) !important;
}

.p-contextmenu .p-menuitem-link {
    padding: 8px 8px;
    font-size: 14px;
}

.p-contextmenu .p-menuitem.p-menuitem>.p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem>.p-menuitem-link .p-submenu-icon {
    font-size: 12px;
}

.p-contextmenu .p-menuitem {
    margin-bottom: 1px;
}