.div__button {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .p-button.p-button-text {
        padding: 0px;
    }

    .p-button.p-button-text:enabled:hover,
    .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
        background: none !important;
        color: #6366F1;
        border-color: transparent;
        padding: 0px;

    }

    .p-button.p-button-text:enabled:active,
    .p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
        background: none !important;
        color: #6366F1;
        border-color: transparent;
    }

    .p-button:focus {
        box-shadow: none !important;
    }
}


.p-timeline-event-opposite {
    display: none;

}

.iconStyle {
    .pi {
        color: black
    }
}

.customized-content {
    .span__date {
        font-size: 12px;
        font-weight: 500;
        color: #545050;
    }

    .span__name {
        font-size: 12px;
        font-weight: 400;
        color: #545050;
        letter-spacing: 0.02px;
        margin-top: 5px;
    }

    .span__content {
        font-size: 11px;
        color: #545050;
    }

    margin-bottom: 15px;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

.p-sidebar .p-sidebar-header {
    padding: 0rem !important;
}