.buttonDeleteIcon {
    color: #EF4444;
}

.buttonSaveIcon {
    color: green
}

.buttonDeleteIcon:hover,
.buttonSaveIcon:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.buttonIconDisabled {
    color: lightgrey;
}

.map-container {

    .p-button.p-button-success,
    .p-button.p-button-danger {
        padding: 5px 5px;
        font-size: 14px;
    }
}