.badge-professional {
    font-weight: 500;
    font-size: 13px;
    color: purple;
}

.badge-companyName {
    font-weight: 500;
    font-size: 13px;
    font-style: italic;
    color: purple;
}

.badge-companyName-dark {
    color: #e7a2e7;
    font-size: 13px;
    font-weight: 500;
}

.badge-individual {
    font-weight: 500;
    font-size: 13px;
    color: darkblue;
}

.badge-individual-dark {
    font-weight: 500;
    font-size: 13px;
    color: #8dd0ff
}

.badge-professional-dark {
    color: #e7a2e7;
    font-size: 13px;
    font-weight: 500;
}

.badge__text {
    font-size: 13px;
    justify-content: center;
    padding: 3px 5px;
}