.reason__div {
    .p-inputtext {
        width: 100%;
        margin-top: 15px;
    }
}

.installation__customButton {

    .p-button.p-button-success,
    .p-buttonset.p-button-success>.p-button,
    .p-splitbutton.p-button-success>.p-button,
    .p-fileupload-choose.p-button-success {
        color: black !important;
        background: rgb(159, 218, 168) !important;
        border: 1px solid rgb(159, 218, 168) !important;
        ;
    }

    .p-button.p-button-warning,
    .p-buttonset.p-button-warning>.p-button,
    .p-splitbutton.p-button-warning>.p-button,
    .p-fileupload-choose.p-button-warning {
        color: black !important;
        ;
        background: rgb(255, 224, 130) !important;
        border: 1px solid rgb(255, 224, 130) !important;
        ;
    }

    .p-button.p-button-danger,
    .p-buttonset.p-button-danger>.p-button,
    .p-splitbutton.p-button-danger>.p-button,
    .p-fileupload-choose.p-button-danger {
        color: black !important;
        background: rgb(241, 158, 166) !important;
        border: 1px solid rgb(241, 158, 166) !important;
    }
}

.div__close {
    display: flex;
    justify-content: end;
}