.p-inputtext.p-component.p-password-input {
    width: 100%;
}

.input-container {
    margin-bottom: 5px;

    .input-box {
        .p-inputtext.p-component.p-password-input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .p-inputtext.p-component.p-password-input:focus {
            box-shadow: none;
        }
    }

    .strength-bar {
        height: 3px;
        width: 100%;
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

.err {
    font-size: 10px;
}