.siret-professional {
    font-weight: 500;
    font-size: 13px;
    font-style: italic;
    color: purple;
}

.siret-professional-dark {
    color: #e7a2e7;
    font-size: 14px;
    font-weight: 500;
}