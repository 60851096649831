.button__go {
  font-size: 18px;
  background-color: #3efffa;
  color: #000;
  font-weight: bold;
  border-radius: 10px;
  padding: 24px 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: background-color 0.2s ease-in-out;
  margin-top: 20px;
}

.modal-body {
  height: 100%;
  text-align: center;
  // align-items: center;
  justify-content: center;

}

.icon__div {
  display: flex;
  justify-content: space-between;
}

.button__close:hover {
  transform: scale(1.02);
  cursor: pointer;
  color: #DC2626;
}

.span__blocked {
  padding: 20px;
  padding-left: 8rem;
  padding-right: 8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}