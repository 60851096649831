.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.p-datatable {
    table-layout: fixed;
    width: 100%;
}

.wrap-text {
    word-wrap: break-word;
    white-space: normal;
}

.row-installation {
    background-color: #D8EFD3 !important;
}

.row-genieCivil {
    background-color: #F1EEDC !important;
}

.row-tes {
    background-color: #FFFAD7 !important;
}

.row-signal {
    background-color: #E3F4F4 !important;
}

.row-produit {
    background-color: #C7C8CC !important;
}

.row-cpo {
    background-color: #E3E1D9 !important;
}

.row-cartes {
    background-color: #F5DAD2 !important;

}

.newProduct-button-dark {
    color: black;
    background: #8dd0ff;
    border: 1px solid #8dd0ff;
    width: 8rem;
    padding: 6px 0px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}