.clientForm__container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.adresss-container {
    display: flex;
    column-gap: 10px;
}

.footer_div {
    float: right;
    padding: 0px 20px;
}

.global-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    align-items: center;
    justify-content: center;
}

.error__div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.icon {

    size: 24;
    color: darkred;
    font-size: 25px;
}

.error__message {
    color: darkred;
    font-weight: 700;
}

.error {
    background-color: #FEE7EF;
    color: #F42C71;
}

.test {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;

    .p-dropdown .p-dropdown-label {
        display: flex;
        justify-content: flex-start;
    }
}

.error_test {
    justify-content: flex-start;
    display: flex;
    margin-top: 2px;
    letter-spacing: 0.1px;
}

.zipcode_city_container {
    display: flex;
    flex-direction: row;
    row-gap: 15px;
    column-gap: 8px;
    justify-content: space-between;
}

.clientStatus__radioGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 25px;
    margin-bottom: 15px;
}

.container__quoteAdress {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.span__phone-warning {
    font-size: 11px;
    justify-content: flex-start;
    display: flex;
}

.item-spacing {
    column-gap: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button__validate {
    background-color: #3EFFFA;
    color: black;
}

.name_lastname_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 8px;
}

.span__address {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    letter-spacing: 0.3px;
    // margin-left: 5px;
    margin-bottom: 5px;
    font-weight: 500;
}

.radiobutton_label {
    font-size: 15px;
}