.icon__edit,
.icon__delete {
    color: #515151
}

.icon__edit:hover,
.icon__delete:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.custom__div {
    display: flex;
    column-gap: 15px;
    align-items: center;
}

.div__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    flex-direction: column;
    width: 60%;
    margin-bottom: 30px;
}

.div__button_filter {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    column-gap: 10px;
    flex-direction: row;

    .p-button.p-button-sm {
        padding: 4px 8px;
    }

    .p-button .p-button-label {
        font-weight: 400;
        font-size: 12px
    }

    .p-button .p-badge {
        // color: #64748B;
        font-size: 10px;
        padding: 0px;
        margin-left: 0px
    }
}

.custom_table {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border: 1px solid #d7d7d7;
    border-radius: 5px;

    .p-inputtext {
        font-size: 12px;
        padding: 6px;
    }

    .p-paginator .p-dropdown {
        height: 2rem;
        margin-left: 0px;
    }

    .p-icon {
        height: 10px;
        width: 10px
    }

    .p-dropdown .p-dropdown-trigger {
        width: 25px
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        font-size: 14px;
    }

    .p-paginator .p-paginator-current {
        height: 2rem;
        font-size: 12px;
    }

    .p-datatable .p-sortable-column .p-sortable-column-icon {
        width: 8px;
        height: 8px;
        color: #7e868c;
    }

    .p-datatable .p-datatable-thead>tr>th {
        background: #F0F2F4;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 12px;
        color: #7e868c;
        border-radius: 2px;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        text-align: left;
        border: 1px solid #dee2e6;
        border-width: 0 0 1px 0;
        padding: 10px 8px;
        font-size: 14px;
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last {
        min-width: 12px;
        height: 2rem;
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        min-width: 2rem;
        height: 2rem;
    }

    .p-tag {
        font-weight: 500;
    }
}

.div__input_icon {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    column-gap: 8px;
}