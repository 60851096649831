.modal-dark {
    .p-inputtext {
        background: #20262e !important;
        color: rgba(255, 255, 255, .87) !important;
    }

    .p-inputtext:enabled:hover {
        border-color: #8dd0ff;
    }

    .p-radiobutton .p-radiobutton-box {
        background: #20262e !important;
        color: rgba(255, 255, 255, .87) !important;
    }

    .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        width: 12px;
        height: 12px;
        transition-duration: 0.2s;
        background-color: rgba(17, 17, 17, 0.608) !important;
        ;
    }

    .p-tabmenu .p-tabmenu-nav {
        background: #2a323d;
        border: 1px solid #2a323d;
        border-width: 0 0 2px 0;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
        background: #2a323d;
        border-color: #8dd0ff;
        color: #8dd0ff;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        border-color: #8dd0ff;
        background: #2a323d;
        color: rgba(255, 255, 255, .87);

    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.1rem #8dd0ffb9;
    }

    .div__button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2a323d;
        flex-direction: row;
    }

    .container__confirmDeal .card {
        background-color: #2a323d;
        border: none;
    }

    .p-accordion .p-accordion-content {
        background-color: #2a323d;
        color: rgba(255, 255, 255, .87);
    }

    .p-accordion .p-accordion-header.acc_test .p-accordion-header-link {
        // transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
        background-color: #9fdaa8;
        color: black;
        // animation: fillBackground 0.2s forwards;
    }

    // @keyframes fillBackground {
    //     from {
    //         width: 0;
    //         background-color: #9fdaa8 !important;
    //     }

    //     to {
    //         width: 100%
    //     }
    // }

    .valid-client {
        color: black;
    }

    .div__calendar {
        display: flex;
        column-gap: 50px;
        align-items: center;
        flex-direction: row;

        .p-calendar .p-inputtext {
            flex: 1 1 auto;
            width: 18rem;
        }

        .p-icon {
            color: white;
        }

        .p-button {
            background: #2a323d;
            border: 1px solid #fff;
        }

        .p-button:enabled:hover,
        .p-button:not(button):not(a):not(.p-disabled):hover {
            background: #2a323dc4;
            border-color: #fff;
        }

        .p-inputtext:enabled:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #2a323dc4;
            border-color: #fff;
        }

        .p-button:focus {
            box-shadow: none;
        }

        .p-inputtext:enabled:hover {
            border-color: #2a323dc4;
        }

        .p-button.p-button-icon-only {
            width: 35px;
            padding: 0.75rem 0;
        }

        .p-datepicker .p-datepicker-header {
            padding: 0px;
        }

        .p-datepicker table td {
            padding: 0px;
        }

        .p-datepicker table th {
            padding: 10px;
        }
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
    .p-accordion .p-accordion-header .p-accordion-header-link {
        background: #20262e;
        color: rgba(255, 255, 255, .87);
    }

    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
        background: #262c35b0;
        border-color: #dee2e6;
        color: rgba(255, 255, 255, .87);
    }

    // .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    //     border-color: #dee2e6;
    //     background: #e9ecef;
    //     color: #343a40;
    // }
    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem #8dd0ff;
    }

    .p-dropdown .p-dropdown-trigger {
        background: #20262e !important;

    }

    .p-dropdown .p-dropdown-label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    ;
}

.modal-light {
    .div__button {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }

    .p-accordion .p-accordion-header.acc_test .p-accordion-header-link {
        background-color: #22C55E;
    }

    .valid-client {
        color: white;
    }

    .div__calendar {
        display: flex;
        column-gap: 50px;
        align-items: center;
        flex-direction: row;

        .p-calendar .p-inputtext {
            flex: 1 1 auto;
            width: 18rem;
        }

        .p-icon {
            color: black;
        }

        .p-button {
            background: #ebebeb;
            border: 1px solid #bcbcbc;
        }

        .p-button:enabled:hover,
        .p-button:not(button):not(a):not(.p-disabled):hover {
            background: #cbccced6;
            border-color: #cbccced6;
        }

        .p-inputtext:enabled:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0.2rem #cbccced6;
            border-color: #bcbcbc;
        }

        .p-button:focus {
            box-shadow: none;
        }

        .p-inputtext:enabled:hover {
            border-color: #cbccced6;
        }

        .p-button.p-button-icon-only {
            width: 35px;
            padding: 0.75rem 0;
        }

        .p-datepicker .p-datepicker-header {
            padding: 0px
        }

        .p-datepicker table td {
            padding: 0px
        }

        .p-datepicker table th {
            padding: 10px;
        }
    }

}