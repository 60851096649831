.header__title {
    font-weight: 500;
}

.div__alignInputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
}

.span__name {
    letter-spacing: 0.3px;
    font-weight: 500;
}

.span__clientInfo {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    // background: #ffffff;
}

.icon__clientInfo {
    font-size: 12px;
}

.span__type {
    font-size: 14px;
    letter-spacing: 0.5px;
}

.div__inputClientRelatedInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .span__title {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 1rem;
    }

    .span__text {
        margin-right: 15px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 1rem;

    }
}