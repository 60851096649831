.p-button.validate-button {
    color: #000000;
    background: #3efffa;
    border: 1px solid #3efffa;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.validate-button:enabled:hover,
.p-button.validate-button:not(button):not(a):not(.p-disabled):hover {
    color: #000000;
    background: #74fffb;
    border: 1px solid #74fffb;
    transform: scale(1.03)
}

.p-button.validate-button:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #3efffa7a, 0 1px 2px 0 rgb(0, 0, 0);
}


.p-button.cancel-button {
    color: black;
    background: #cbccced6;
    border: 1px solid #cbccced6;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.cancel-button:enabled:hover,
.p-button.cancel-button:not(button):not(a):not(.p-disabled):hover {
    color: #000000;
    background: #cbccced6;
    border: 1px solid #cbccced6;
    transform: scale(1.03)
}

.p-button.cancel-button:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #cbccced6, 0 1px 2px 0 rgb(0, 0, 0);
}

.p-button.filter-button {
    color: white;
    background: #0061ff;
    border: 1px solid #0061ff;
    width: 8rem;
    padding: 6px 0px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.filter-button:enabled:hover,
.p-button.filter-button:not(button):not(a):not(.p-disabled):hover {
    color: white;
    background: #0061ffeb;
    border: 1px solid #0061ffeb;
    transform: scale(1.01)
}

.p-button.filter-button:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #0061ff, 0 1px 2px 0 rgb(0, 0, 0);
}

.p-button.filter-button-dark {
    color: black;
    background: #8dd0ff;
    border: 1px solid #8dd0ff;
    width: 8rem;
    padding: 6px 0px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.filter-button-dark:enabled:hover,
.p-button.filter-button:not(button):not(a):not(.p-disabled):hover {
    color: black;
    background: #8dd0ffce;
    border: 1px solid #8dd0ffce;
    transform: scale(1.01)
}

.p-button.filter-button-dark:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #8dd0ff, 0 1px 2px 0 rgb(0, 0, 0);
}

.p-button.validateDistance-light {
    color: white;
    background: #22c55e;
    border: 1px solid #22c55e;
    width: 10rem;
    padding: 6px 0px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.validateDistance-light:enabled:hover,
.p-button.validateDistance-light:not(button):not(a):not(.p-disabled):hover {
    color: white;
    background: #22c55ed3;
    border: 1px solid #22c55ed3;
    transform: scale(1.01)
}

.p-button.validateDistance-light:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #22c55e, 0 1px 2px 0 rgb(0, 0, 0);
}

.p-button.validateDistance-dark {
    color: black;
    background: #9fdaa8;
    border: 1px solid #9fdaa8;
    width: 10rem;
    padding: 6px 0px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.validateDistance-dark:enabled:hover,
.p-button.validateDistance-dark:not(button):not(a):not(.p-disabled):hover {
    color: black;
    background: #9fdaa8de;
    border: 1px solid #9fdaa8de;
    transform: scale(1.01)
}

.p-button.validateDistance-dark:focus {
    box-shadow: 0 0 0 2px black, 0 0 0 4px #9fdaa8, 0 1px 2px 0 rgb(0, 0, 0);
}


.p-button.delete-dark {
    color: black;
    background: #f19ea6;
    border: 1px solid #f19ea6;
    width: 7rem;
    padding: 6px 0px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.delete-dark:enabled:hover,
.p-button.delete-dark:not(button):not(a):not(.p-disabled):hover {
    color: black;
    background: #f19ea6d2;
    border: 1px solid #f19ea6d2;
    transform: scale(1.01)
}

.p-button.delete-dark:focus {
    box-shadow: 0 0 0 2px black, 0 0 0 4px #f19ea6, 0 1px 2px 0 rgb(0, 0, 0);
}

.p-button.delete-light {
    color: white;
    background: #EF4444;
    border: 1px solid #EF4444;
    width: 7rem;
    padding: 6px 0px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.delete-light:enabled:hover,
.p-button.delete-light:not(button):not(a):not(.p-disabled):hover {
    color: white;
    background: #ef4444d0;
    border: 1px solid #ef4444d0;
    transform: scale(1.01)
}

.p-button.delete-light:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #EF4444, 0 1px 2px 0 rgb(0, 0, 0);
}

.p-button.option-dark,
.p-button.option-light {
    color: black;
    background: #cbccced6;
    border: 1px solid #cbccced6;
    width: 8rem;
    padding: 6px 0px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.option-dark:enabled:hover,
.p-button.option-dark:not(button):not(a):not(.p-disabled):hover,
.p-button.option-light:enabled:hover,
.p-button.option-light:not(button):not(a):not(.p-disabled):hover {
    color: black;
    background: #cbcccea9;
    border: 1px solid #cbccced6;
    transform: scale(1.01)
}

.p-button.option-dark:focus,
.p-button.option-light:focus {
    box-shadow: 0 0 0 2px black, 0 0 0 4px #cbccced6, 0 1px 2px 0 rgb(0, 0, 0);
}

.p-button.quote-badge-dark {
    font-size: 12px;
    height: 30px;
    border-radius: 12px;
    background: #8dd0ff;
    color: black;
    padding: 4px 10px;
    letter-spacing: 0.3px;
    border: none;
}

.p-button.quote-badge-dark:enabled:hover,
.p-button.quote-badge-dark:not(button):not(a):not(.p-disabled):hover {
    color: black;
    background: #8dd0ff;
    border: none;
    transform: scale(1.01)
}

.p-button.quote-badge-light {
    font-size: 12px;
    height: 30px;
    border-radius: 12px;
    background: #cee4fe;
    color: black;
    padding: 4px 10px;
    letter-spacing: 0.3px;
    border: none;
}

.p-button.quote-badge-light:enabled:hover,
.p-button.quote-badge-light:not(button):not(a):not(.p-disabled):hover {
    color: black;
    background: #cee4fe;
    border: none;
    transform: scale(1.01)
}

.p-button.validate-button-QuickDevis {
    color: #000000;
    background: #3efffa;
    border: 1px solid #3efffa;
    padding: 8px 12px;
    font-size: 18px;
    font-weight: 500;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-button.validate-button-QuickDevis:enabled:hover,
.p-button.validate-button-QuickDevis:not(button):not(a):not(.p-disabled):hover {
    color: #000000;
    background: #74fffb;
    border: 1px solid #74fffb;
    transform: scale(1.03)
}

.p-button.validate-button-QuickDevis:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #3efffa7a, 0 1px 2px 0 rgb(0, 0, 0);
}

.p-button.buttonpointsdecharge {
    height: 35px;
    border-radius: 20px;
    color: white;
    font-size: 13px;
    border: none;
    font-weight: bold;
    background-color: black;
}