.label__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #191919;
    float: left;
    margin-left: 14px;
}

.p-tabmenuitem {
    width: 50%;
}

.p-tabmenu .p-tabmenu-nav.p-reset {
    border: none;
}

.p-tabmenu.p-component {
    margin-bottom: 0;
}

.div_prices {
    display: flex;
}

.p-multiselect-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
    z-index: 9999;
}

.row__selected.row {
    border: 2px solid #4338CA;
    border-radius: 4px;
}

.div__comment {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.span__titleComment {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #191919;
    margin-left: 14px;
}

.span__commentDescription {
    font-size: 13px;
    line-height: 19px;
    color: #191919;
    margin-left: 14px;
    margin-right: 14px;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: 0 0 0 1px #C7D2FE !important;
}

.p-accordion .p-accordion-content {
    padding: 15px;
}

.multiselct__accessories {
    .p-multiselect .p-multiselect-label {
        padding: 8px;
        font-size: 14px;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        margin: 0;
        font-size: 13px;
        padding: 8px;
    }
}

.div__extra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .p-button.p-button-text:enabled:hover,
    .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
        background: none !important;
        color: #6366F1;
        border-color: transparent;
    }

    .p-button.p-button-text:enabled:active,
    .p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
        background: none !important;
        color: #6366F1;
        border-color: transparent;
    }

    .p-button:focus {
        box-shadow: none !important;
    }
}

.extraProducts__div {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: flex-start;
    margin-bottom: 5px;

    .title__extra {
        font-size: 13px;
    }

    .product__div {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 8px;
        width: 100%;

        .p-inputtext.p-component.p-filled.p-inputnumber-input.p-inputnumber-input {
            width: 100%;
        }
    }

    .price__div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 8px;
    }

    .p-inputtext {
        padding: 5px;
    }

    .p-inputtext:enabled:focus {
        box-shadow: 0 0 0 1px #C7D2FE;
    }
}