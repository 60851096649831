.card__container {
    display: flex;
    column-gap: 15px;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;

}

.div__filterTitle {
    margin-top: -10px;
}

.span__filterTitle {
    font-size: 16px;
    font-weight: 500;
}

.price__div {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.div__cardText {
    display: flex;
    flex-direction: row;
}

.span_type {
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: #bf4800;
    display: block;
}

.span__text {
    font-size: 12px;
    letter-spacing: 0.3px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
}

.span__price {
    font-size: 14px;
    line-height: 1.28577;
    font-weight: 600;
    letter-spacing: 0.3px;
    font-family: SF Pro Text, SF Pro Icons, AOS Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    padding-top: 0;
    margin-top: auto;
    white-space: normal;
    text-align: end;
}




.image__card {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}

.cardFront {
    height: 390px;
    width: 320px;
    position: relative;
    transition: transform 900ms;
    transform-style: preserve-3d;
}

.cardFront:hover {
    transform: scale(1.03);
}

.cardBack {
    height: 390px;
    width: 320px;
    position: relative;
    transition: transform 900ms;
    transform-style: preserve-3d;
    cursor: pointer;
    transform: rotateY(180deg);
}

.front:hover,
.back:hover {
    cursor: pointer;

    .span_type,
    .span__text {
        font-weight: 600;
    }
}

.front,
.back {
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0 0 5px 2px rgba(50, 50, 50, 0.25);
    position: absolute;
    backface-visibility: hidden;
    box-shadow: 0 3px 10px rgb(0 0 0 / 35%);

}

.front {
    padding-bottom: 10px
}

.back {
    transform: rotateY(180deg);
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: start;
    box-shadow: 0 3px 10px rgb(0 0 0 / 35%);

    .span__brand {
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 600;
    }

    .div__description {
        height: 55%;
        display: flex;
        flex-direction: column;
        align-items: start;

        .span__description {
            font-size: 13px;
            line-height: 1.23536;
            font-weight: 400;
            font-family: SF Pro Text, SF Pro Icons, AOS Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
            text-align: start;
            font-style: italic;
            // -webkit-line-clamp: 3;
            // -webkit-box-orient: vertical;
            // display: -webkit-box;
            // overflow: hidden;
            // white-space: normal;
        }
    }

    .div__technique {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;

        .span_subtitle {
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .span__techTitle {
            font-size: 13px;
            letter-spacing: 0.5px;
            font-weight: 600;
            justify-content: flex-start;
            display: flex;
        }

        .span__techDescription {
            font-size: 12px;
        }
    }




}