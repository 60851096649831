@media (max-width: 576px) {
  .custom-modal {
    width: 90%;
  }
}

@media (min-width: 650px) {
  .custom-modal {
    width: 60%;
  }
}

.custom__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -8px;
  margin-bottom: 35px;
}

.step__title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 15px;
  padding: 0px 4rem;
  letter-spacing: 0.5px;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #495057;
}

.content__container {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 15px;
  flex-wrap: wrap;
}

.card__selected {
  border: 2px solid #3981F6;
  border-radius: 10px;
}