.modal__container {
   padding: 20px;
}

.footer__div {
   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 8px;
   margin-top: 10px;
   margin-bottom: 10px;
}

.button__cancel {
   background-color: #cfcfcf;
   color: black;
}

.button__login {
   background-color: #3EFFFA;
   color: black;
}


.div__reset-pwd {
   display: flex;
   justify-content: center;

   .span__reset-pwd {
      font-size: 12px;
      text-decoration: underline;
      color: blue;
      cursor: pointer;
   }
}