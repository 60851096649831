.nextui-c-kNgzEX {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.custom__div {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.refresh__icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.div__filter {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  flex-direction: column;
  width: 60%;
  margin-bottom: 30px;
}

.icon__filter {
  font-size: 20px;
  color: #889096
}

.icon__filter:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.div__input_icon {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  column-gap: 8px;
}

.div__button_filter {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  column-gap: 10px;
  flex-direction: row;

  .p-button.p-button-sm {
    padding: 4px 8px;
  }

  .p-button .p-button-label {
    font-weight: 400;
    font-size: 12px
  }

  .p-button .p-badge {
    // color: #64748B;
    font-size: 10px;
    padding: 0px;
    margin-left: 0px
  }
}