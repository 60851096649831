.clientcard__maincol {
    padding: 12px;
}

.clientcard__container__left {
    margin: 0;
    width: 50%;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clientcard__container__right {
    margin: 0;
    width: 50%;
    padding-left: 40px !important;
    padding-top: 25px !important;
}