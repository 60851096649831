.title {
    font-size: 50px;
    font-weight: bold;
}

.subtitle {
    font-size: 30px;
    margin-bottom: 50px;
    color: "#333";
    text-align: "center"

}

.paragraph {
    font-size: 20px;
    margin-bottom: 50px;
    color: "#333";
    text-align: "center"
}

.buttonStyle {
    font-size: 24px;
    background: #3EFFFA;
    color: #000;
    font-weight: bold;
    border-radius: 10px;
    padding: 24px 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    transition: background-color 0.2s ease-in-out;
}

.div__welcome {
    padding: 0px 3rem;
}

.welcome-message {
    font-size: 18px;
    text-align: justify;
    display: block;
    font-weight: 700;
}

.sub_welcome-message {
    font-size: 14px;
    // margin-top: 15px;
    text-align: justify;
    display: block;
    font-weight: 500;
}

.custom_div_for_card-web {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 1px -1px 6px rgb(0 0 0 / 10%);
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
    background-color: rgb(61 255 250 / 3%);
    padding: 20px 20px;
    width: 40%;
    float: right;
    flex-wrap: wrap;
    display: flex;
    row-gap: 10px;
    // justify-content: center;

}


.custom_div_for_card-phone {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 1px -1px 6px rgb(0 0 0 / 10%);
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
    background-color: rgb(61 255 250 / 3%);
    padding: 20px 20px;
    width: 95%;
    float: right;
    flex-wrap: wrap;
    display: flex;
    row-gap: 10px;
    // justify-content: center;
    margin: 2rem auto;
}

.div_customInputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 50px;
}

.span__input {
    font-size: 15px;
    color: #474747;
    font-weight: 500;
    float: left;
    margin-left: 18px;
}

.contact-web {
    font-size: 32px;
    letter-spacing: 0.02px;
    font-weight: 600;
    padding-bottom: 11px;
}

.contact-phone {
    font-size: 25px;
    letter-spacing: 0.02px;
    font-weight: 600;
    padding-bottom: 11px;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.main-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 4rem;
}

.subContainer {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
}

.ems-phone {
    width: 100%;
}

.ems-web {
    width: 60%;
}

.ems-web-structure {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    padding-left: 6rem;
    padding-right: 6rem;
}

.ems-phone-structure {
    display: flex;
    flex-direction: column;
}

.homeStyle {
    margin: 0px 0px !important;
}