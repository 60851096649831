.status-open {
  font-weight: 600;
  background-color: #b6dffc;
  border: 2px solid #269cf2;
  color: #005491;
  height: 30px;
  border-radius: 10px;
}

.status-win {
  font-weight: 600;
  background-color: #ADC4A3;
  border: 2px solid #017705;
  color: #3A8219;
  height: 30px;
  border-radius: 10px;
}

.status-lose {
  font-weight: 600;
  background-color: #A9A4A4;
  border: 2px solid #191919;
  height: 30px;
  border-radius: 10px;
}

.status-warning {
  font-weight: 600;
  background-color: #FCF8E3;
  border: 2px solid #C9A86B;
  color: #C9A86B;
  height: 30px;
  border-radius: 10px;
}

.badge__text {
  font-size: 14px;
  padding: 10px
}

.info-dark {
  background-color: #7fd8e6 !important;
  color: black !important;
}

.success-dark {
  background-color: #9fdaa8 !important;
  color: black !important;
}

.danger-dark {
  background-color: #f19ea6 !important;
  color: black !important;
}

.warning-dark {
  background-color: #ffe082 !important;
  color: black !important;
}

.p-tag {
  width: 7rem
}