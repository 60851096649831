.newUser-button-dark {
    color: black;
    background: #8dd0ff;
    border: 1px solid #8dd0ff;
    width: 8rem;
    padding: 6px 0px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}