.custom__multiselect {
    .p-icon {
        width: 10px;
        height: 10px;
    }

    .p-multiselect .p-multiselect-label.p-placeholder,
    .p-multiselect .p-multiselect-label {
        color: #6c757d;
        padding: 7px;
        font-size: 12px;
        float: left;
    }

    .p-multiselect-panel .p-multiselect-header {
        padding: 9px;
        border-bottom: 1px solid #dee2e6;
        color: #343a40;
        background: #f8f9fa;
        margin: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        margin: 0;
        padding: 6px;
        border: 0 none;
        color: #495057;
        background: transparent;
        transition: box-shadow 0.2s;
        border-radius: 0;
        font-size: 12px;
    }

    .p-checkbox .p-checkbox-box {
        border: 2px solid #ced4da;
        background: #ffffff;
        width: 16px;
        height: 16px;
    }

    //Dropdown
    .p-dropdown .p-dropdown-label {
        background: transparent;
        border: 0 none;
        float: left !important;
        font-size: 12px;
        justify-content: start;
        display: flex;
        padding: 8px;
        width: 100%;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        justify-content: start;
        display: flex;
    }
}

.div__section {
    display: flex;
    align-items: center;
    column-gap: 7px;

    span {
        font-size: 12px;
    }


}

.price {
    .p-inputtext {
        margin: 0;
        width: 100%;
        padding: 7px;
        font-size: 12px;
    }
}

.div__footer {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}