.upload {
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 6px;
    margin-bottom: 16px;
}

.div-uploadedInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    column-gap: 10px
}

.p-fileupload .p-fileupload-content {
    background: #ffffff;
    padding: 10px 0px 0 !important;
    color: #495057;
    border: none 0 !important;
}

.p-fileupload .p-fileupload-buttonbar {
    background: #f8f9fa;
    padding: 0 !important;
    border: none 0 !important;
    color: #343a40;
    margin-bottom: 10px;
    gap: 0.5rem;
}

.upload .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 40px !important;
    width: 40px !important;
}