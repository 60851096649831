.p-accordion .p-accordion-header.acc_test .p-accordion-header-link:hover {
    outline: 0 none;
    outline-offset: 0;
    background-color: red;
    color: black
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #2773be;
    border-color: #2773be;
    color: #343a40;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.col__title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.text__confirmDeal {
    font-weight: 600;
    letter-spacing: 0.5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    // color: #495057;
}

.p-dialog-draggable .p-dialog-header {
    padding: 20px;
    padding-bottom: 10px;
}

.container__confirmDeal {
    margin-top: 10px;

    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }

}

.span__Info {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    // color: #495057;
    letter-spacing: 0.2px;
    font-weight: 400
}

.span__Title {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
    // color: #495057;
    letter-spacing: 0.2px;
    font-weight: 500;
    padding-left: 0px;
}

.span__categorie {
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    // color: #020202;
    letter-spacing: 0.2px;
    font-size: 16px;
}

hr {
    margin-top: 12px;
    margin-bottom: 12px;
}

.custom__footer {
    .p-button {
        color: #ffffff;
        background: #717171;
        border: 1px solid #717171;
        padding: 7px;
        font-size: 15px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 5px;
    }
}

.nextui-c-grJsex-ikUaMfZ-css {
    max-width: 500px;
}