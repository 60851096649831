.div__firstColumn {
    display: flex;
    align-items: center;
    column-gap: 5px;
    flex-wrap: nowrap;

    .icon__exclamation {
        font-size: 15px;
        color: #F59E0B;
    }
}

.icon__add {
    font-size: 15px;
}