.divImage {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divImageEclaire {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.divButton {
    height: 35px;
    border-radius: 20px;
    color: white;
    font-size: 13px;
    border: none;
    font-weight: bold;
    background-color: black;
}

.divButton2 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.divPoliceTexteCarte {
    font-size: 25px;
    font-weight: bold;
}

.divPoliceTexteUsage {
    font-size: 27px;
    font-weight: bold;
    color: black;
}

.divPoliceTexteUsageSub {
    font-size: 20px;
    font-weight: bold;
    color: black;
}

.divPoliceEtapeName {
    font-size: 17px;
    color: black;
}

.divPoliceEtapeContenue {
    font-size: 14px;
    color: black;

}

.divPoliceTexteAvantage {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    line-height: 32px;
}

.divEntete {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.divTexteAccroche {
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.subtitle {
    align-items: center;
    justify-content: center;
}

.divEtapes {
    background-color: #e2e7e7;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.divAvantages {
    background-color: #b5ebe8;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.divSubAvantages {
    justify-Content: "center"
}

.divEspaces {
    background-color: #ffffff;
    height: 10px;
}

.etapes {
    padding-top: 10px;
    padding-bottom: 10px;
}

.etapesTitre {
    padding-bottom: 20px;
}