.radioButton__distance {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 5px;
    margin-bottom: 15px;
    align-items: center;
}

label {
    font-size: 14px;
}

.icon__validateDistance {
    color: #00c051;

}

.icon__validateDistance:hover {
    transform: scale(1.3);
    cursor: pointer;
}

.button__validateDistance {
    border: 1px solid transparent;
    background-color: transparent;
}

.submitManualInputButton {

    .p-button.p-button-success,
    .p-buttonset.p-button-success>.p-button,
    .p-splitbutton.p-button-success>.p-button,
    .p-fileupload-choose.p-button-success {

        padding: 4px 12px;
        font-size: 14px;
    }
}