.header__productName {
    margin-top: 10px;
    float: left;
    letter-spacing: 0.25px;
}

.body__description {
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.01px;
    margin-bottom: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.text_instruction {
    font-size: 14px;
    letter-spacing: 0.01px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px;
    margin-top: -8px
}

.footer__text {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
}

.card__selectedEffect {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.card__selectedEffect:hover {
    background-color: rgba(157, 193, 251, 0.209);
    transform: scale(1.01);
}

.image__cardStation {
    width: 7rem !important;
    height: 7rem !important;
    float: right;
}

.div__StationSubtitle {
    display: flex;
    column-gap: 5px;
    align-items: center;
    margin-bottom: 5px;
}

.span__typeSub {
    font-size: 11px;
    font-weight: 600;
}

.span__text {
    font-size: 10px;
    font-style: italic;
}