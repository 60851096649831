.modal-dark {
    .p-inputtext {
        background: #20262e;
        color: rgba(255, 255, 255, .87);
    }

    .p-inputtext:enabled:hover {
        border-color: #8dd0ff;
    }
}

.icon__editUser {
    font-size: 60px;
    margin-top: -18px;
    position: fixed;
    margin-left: 8px;
    // color: #4338CA
}

.header__title {
    font-weight: 500;
}

.div__input {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;

    .p-dropdown .p-dropdown-label {
        display: flex;
        justify-content: flex-start;
    }
}

.container__inputs {
    display: flex;
    flex-direction: row;
    row-gap: 15px;
    column-gap: 15px;
    justify-content: center;
}

.span__section {
    font-size: 14px;
    // color: #495057;
    display: flex;
    justify-content: flex-start;
    letter-spacing: 0.3px;
    margin-bottom: 15px;
    font-weight: 500;
}

.small__error {
    justify-content: flex-start;
    display: flex;
    margin-top: 2px;
    letter-spacing: 0.1px;
    font-size: 12px;
}

.button__modify {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.div__modification {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 5px;
    margin-top: 5px
}

.icon__check {
    color: green;
    font-size: 12px;
}

.span__modif {
    letter-spacing: 0.1px;
    font-size: 12px;
    color: green
}