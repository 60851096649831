.div__buttonsComments {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 0px;
    margin-top: 14px;
}

.div__comentDisplay {
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 12px;
}

.div__col1 {
    display: flex;
    flex-direction: column;
    align-items: start;
}

hr {
    background-color: #7f7f7f;
}