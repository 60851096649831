body {
  background-color: transparent;
}

.homeStyle {
  .p-inputtext {
    width: 100%;
    padding: 6px;
    background: #ffffff78;
    border: 1px solid #9d9d9d;
  }
}