.custom-calendar {
    z-index: 1001;
}

.p-datepicker.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
    z-index: 9999 !important;
}

.div__footer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 0 20px;
    column-gap: 10px;
    margin-bottom: 25px;
}

.span__dateTitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    float: left;
}

.span__dateTitle {
    font-size: 16px;
    line-height: 19px;
    float: left;
}

.span__link {
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -.022em;
    font-family: SF Pro Text, SF Pro Icons, AOS Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    text-align: right;
    margin-top: 0;
    color: #06c;
    padding: 0 15px;
}

.span__link-dark {
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -.022em;
    font-family: SF Pro Text, SF Pro Icons, AOS Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
    text-align: right;
    margin-top: 0;
    color: #7fd8e6;
    padding: 0 15px;
}

.span__link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.div__calendar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin: 20px 0px
}

.header__badge--dark {
    font-size: 12px;
    border-radius: 12px;
    background: #8dd0ff;
    color: black;
    padding: 5px 100%;
    letter-spacing: 0.3px;
}