.header__badge {
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #59527C;
}

.header__productName {
    margin-top: 10px;
    float: left;
    letter-spacing: 0.25px;
}

.body__description {
    font-size: 13px;
    letter-spacing: 0.01px;
    margin-bottom: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.text_instruction {
    font-size: 14px;
    letter-spacing: 0.01px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 20px;
    margin-top: -8px
}

.span__descriptionNote {
    font-style: italic;
    font-weight: 500;
}

.body__bulletPoints {
    column-gap: 5px;
    align-items: center;
}

.body__icon-light {
    color: #2e518b;
    font-size: 14px;
    margin-top: -4px;
}

.body__iconConnected-light {
    color: #59527C;
    font-size: 14px;
    margin-top: -4px;
}

.body__icon-dark {
    color: #8dd0ff;
}

.body__textIcon {
    letter-spacing: 0.05px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
}

.footer__text {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
}

.span__priceNote {
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-right: 16px;
}


.step__title--installateur {
    font-size: 14px;
    padding: 0px 4rem;
    letter-spacing: 0.5px;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #495057;
    font-style: italic;
    margin-top: -22px;

}

.step__title--forfait {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 15px;
    padding: 0px 4rem;
    letter-spacing: 0.5px;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #495057;
}

.card__selectedEffect {
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.card__selectedEffect:hover {
    background-color: rgba(157, 193, 251, 0.209);
    transform: scale(1.01);
}

.title_icon {
    display: flex;
    align-items: center;
    column-gap: 8px;
}