.row__custom {
  height: 100%;
}

.col__custom {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.container__homepage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 4rem;

  .div__colOne {
    width: 30%;

    .div__quoteButtton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

    }
  }

  .div__colTwo {
    width: 70%;
    padding-left: 20px;

    .div__users {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
      column-gap: 15px;
    }
  }

  .div_activity {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;

    .span__homepageTitle {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
  }

  .div__sharedCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 15px;
    row-gap: 10px;
    flex-wrap: wrap;
    // margin-bottom: 8px;

  }

  .div__usersTop3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 25px;
    row-gap: 10px;
    flex-wrap: nowrap;
    margin-bottom: 35px;

    .subDiv__users {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      column-gap: 8px;

    }

    .div__userInfo {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      column-gap: 10px;
    }

    .span__info {
      font-size: 15px;
    }
  }

}