.dropdown__button {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown__button-active {
  border-bottom: 5px solid #3EFFFA;
  border-radius: 0;
}

.dropdown__menu {
  & .nextui-dropdown-item-content {
    width: 100%;
    font-weight: 600;
    transition: color 0.3s;

    &:hover {
      color: black;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -8px;
        height: 100%;
        width: 4px;
        background-color: #3EFFFA;
      }
    }
  }
}

.switch-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nextui-c-hPzDAx .nextui-collapse-title-content h1,
.nextui-c-hPzDAx .nextui-collapse-title-content h2,
.nextui-c-hPzDAx .nextui-collapse-title-content h3,
.nextui-c-hPzDAx .nextui-collapse-title-content h4,
.nextui-c-hPzDAx .nextui-collapse-title-content h5,
.nextui-c-hPzDAx .nextui-collapse-title-content h6,
.nextui-c-hPzDAx .nextui-collapse-title-content p,
.nextui-c-hPzDAx .nextui-collapse-title-content span,
.nextui-c-hPzDAx .nextui-collapse-title-content b {
  margin: 0px;
  font-size: 18px;
  font-weight: 400;
}

.navBar__phone--title {
  font-size: 18px;
  font-weight: 400;
  margin-left: 6px
}

.nextui-c-dWWWSv-eaqZHc-borderWeight-light,
.nextui-c-fMAxGR {
  padding: 0;
}

.nextui-c-lfcDHB {
  padding: 6px
}

.nextui-c-PJLV-ijnlmIz-css {
  margin-bottom: 4px;
}

.navbar-link.active,
.navbar-link:hover {
  transform: scale(120%);
  color: #003f3e;
}